import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
import { Button, Input, Modal, Radio } from "antd";
import {
  DownloadOutlined,
  UndoOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "../Styles/ImportPage.css";
import * as XLSX from "xlsx";

const ImportPage = () => {
  const { Search } = Input;
  const { admin } = useContext(multiStepContext);
  const [data, setData] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [network, setNetwork] = useState("");
  const [publish, setPublish] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editPublish, setEditPublish] = useState("");
  const [editItemId, setEditItemId] = useState(null);
  const fileInputRef = useRef(null);
  const [userData, setUserdata] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("disabled");

  const fetchData = async () => {
    try {
      const response = await Axios.get(`${apiURL}upload/getAllFile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data.success);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    Axios.get(`${apiURL}getOperator`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        setNetworks(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {

    if (!file) {
      alert("กรุณาเลือกไฟล์");
      return;
    }

    if (admin.role !== 'operator' && !selectedNetwork) {
      alert("กรุณาเลือกเครือข่าย");
      return;
    }

    try {
      let apiUrl = `${apiURL}upload/excel/`;

      let requestBody = {
        fileName: file.name,
        type: selectedButton,
        operatorId: selectedNetwork,
        fileStatus: publish,
        data: userData,
      };

      if (admin && admin.role !== null) {
        if (admin.role === "operator") {
          requestBody.operatorId = selectedNetwork || admin.operator_id;
        } else {
          requestBody.operatorId = selectedNetwork;
        }
      } else {
      }

      if (selectedButton === "disabled") {
        apiUrl += "disabled";
      } else if (selectedButton === "student") {
        apiUrl += "student";
      } else {
        apiUrl += "admin/uploadFile";
      }

      const response = await Axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setIsModalOpen(false);
      setFile(null);
      setNetwork("");
      setPublish("");
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
      fetchData();
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEditOk = async (itemId) => {
    try {
      const editURL = `${apiURL}upload/admin/editFile/${itemId}`;
      const response = await Axios.put(
        editURL,
        {
          file_status: "complete",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsEditModalOpen(false);
      setEditPublish("");
      setData((prevData) => prevData.filter((item) => item.id !== itemId));
      fetchData();
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    clearFileInput();
  }, [selectedButton]);

  const showDeleteModal = (userId) => {
    setIsDeleteModalOpen(true);
    setUserIdToDelete(userId);
  };

  const handleDownload = async (itemId) => {
    try {
      const downloadURL = `${apiURL}admin/downloadFiles/${itemId}`;
      const response = await Axios.get(downloadURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      let data;
      if (selectedButton === 'student') {
        data = response.data.data.recordStudent;
      } else {
        data = response.data.data.recordDisabled;
      }

      const rows = data.map(item => {
        if (selectedButton === "student") {
          return {
            เลขบัตรประชาชน: item.s_national_id,
            ชื่อ: item.s_firstname,
            นามสกุล: item.s_lastname,
            หมายเลขโทรศัพท์มือถือ: item.s_phone,
            ระดับชั้น: item.degree,
            เครือข่าย: item.s_operator,
            ขอรับซิมการ์ดใหม่: item.s_purpose,
            วันที่ได้ข้อมูล: item.date,
            ผลการอนุมัติ: item.approve_status,
            เหตุผล: item.approve_reason,
          };
        } else {
          return {
            เลขบัตรประชาชน: item.d_national_id,
            ชื่อ: item.d_firstname,
            นามสกุล: item.d_lastname,
            หมายเลขโทรศัพท์มือถือ: item.d_phone,
            เครือข่าย: item.d_operator,
            ขอรับซิมการ์ดใหม่: item.d_purpose,
            วันที่ได้ข้อมูล: item.date,
            ผลการอนุมัติ: item.approve_status,
            เหตุผล: item.approve_reason,
          };
        }
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows);

      const textFormatColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
      textFormatColumns.forEach(col => {
        rows.forEach((item, index) => {
          worksheet[col + (index + 2)].z = '@';
        });
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const FileName = (selectedButton === "disabled" ? "ข้อมูลผู้พิการ" : "ข้อมูลนักเรียน")
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${FileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      fetchData();
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };


  const handleDeleteOk = async () => {
    try {
      const deleteURL = `${apiURL}deleteImportFile/${userIdToDelete}`;
      const response = await Axios.delete(deleteURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("User deleted:", response.data);
      setIsDeleteModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    Axios.get(`${apiURL}getPackage`, { headers })
      .then((res) => {
        console.log("Packages from API:", res.data);
        const transformedData = res.data.map((item) => ({
          packageId: item.id,
          packageDetail: item.packageDetail,
        }));
        setPackages(transformedData);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const excelColumnHeaders = sheetData[0];

      const expectedColumnHeaders =
        selectedButton === "disabled"
          ? [
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "วันที่ได้ข้อมูล",
            "สถานะ",
            "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
            "เหตุผล (กรณีไม่ผ่าน)",
          ]
          : [
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "ระดับชั้น",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "วันที่ได้ข้อมูล",
            "สถานะ",
            "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
            "เหตุผล (กรณีไม่ผ่าน)",
          ];

      const columnsMatch =
        JSON.stringify(excelColumnHeaders) ===
        JSON.stringify(expectedColumnHeaders);

      if (columnsMatch) {
        alert("รูปแบบไฟล์ถูกต้อง");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("selectedButton", selectedButton);

        const sheetDataWithoutHeader = sheetData.slice(1);
        const formattedData = sheetDataWithoutHeader.map((row, index) => {
          if (selectedButton === "disabled") {
            return {
              Client: index,
              citizenId: row[0],
              firstname: row[1],
              lastname: row[2],
              service: row[3],
              purpose: row[4],
              phoneNumber: row[5],
              date: row[6],
              status: row[7],
              approve_status: row[8],
              approve_reason: row[9],
            };
          } else if (selectedButton === "student") {
            return {
              Client: index,
              citizenId: row[0],
              firstname: row[1],
              lastname: row[2],
              degree: row[3],
              service: row[4],
              purpose: row[5],
              phoneNumber: row[6],
              date: row[7],
              status: row[8],
              approve_status: row[9],
              approve_reason: row[10],
            };
          }
        });


        setUserdata(formattedData);
        console.log(formattedData)


      } else {
        alert("รูปแบบไฟล์หรือขนาดไฟล์ไม่ถูกต้อง");
        clearFileInput();
        setFile(null);
      }
    };
    reader.readAsArrayBuffer(file);
  };


  const handleEditPublishChange = (e) => {
    setEditPublish(e.target.value);
  };

  const [searchValue, setSearchValue] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.file_name &&
      item.file_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleNetworkChange = (selectedNetworkId) => {
    setSelectedNetwork(selectedNetworkId);
  };

  const Table = () => {
    let filteredDataByType = [];

    if (selectedButton === "disabled") {
      filteredDataByType = data.filter(
        (item) => item.uploadType === "disabled"
      );
    } else if (selectedButton === "student") {
      filteredDataByType = data.filter((item) => item.uploadType === "student");
    }

    console.log(filteredDataByType)
    // If admin.role is not operator, render all data without filtering
    if (admin.role !== "operator") {
      return (
        <div className="import-scroll">
          <div className="container">
            <div className="inner-con">
              <style>
                {`
                  * {
                    box-sizing: border-box;
                  }

                  .table-container {
                    border-collapse: collapse;
                    width: 100%;
                    border: 1px solid black;
                    margin-top: 20px;
                  }

                  .table-container th {
                    padding: 15px;
                    text-align: center; /* Align center horizontally */
                    vertical-align: middle; /* Align center vertically */
                    background-color: rgb(239, 147, 108);
                  }

                  .table-container td {
                    padding: 15px;
                    text-align: center; /* Align center horizontally */
                    vertical-align: middle; /* Align center vertically */
                  }

                  .table-container tr:hover {
                    background-color: #f5f5f5;
                  }

                  .inner-con {
                    width: 100%;
                    display: flex;
                  }
                `}
              </style>
              <table className="table-container">
                <thead>
                  <tr>
                    <th>รายการ</th>
                    <th>เครือข่าย</th>
                    <th>ผู้อัปโหลด</th>
                    <th>การเผยแพร่</th>
                    <th>อัปโหลดเมื่อ</th>
                    <th>จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataByType
                    .sort((a, b) => {
                      if (
                        a.status === "pending" &&
                        b.status === "complete"
                      ) {
                        return -1;
                      } else if (
                        a.status === "complete" &&
                        b.status === "pending"
                      ) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .map((item) => (
                      <tr key={item.id}>
                        <td>{item.file_name}</td>
                        <td>{item.operators.company_name}</td>
                        <td>
                          {item.admin.username}
                        </td>

                        <td>
                          {item.status === "pending" ? "ไม่" : "ใช่"}
                        </td>
                        <td>
                          {new Date(item.createDate).toLocaleString()}
                        </td>
                        <td className="import-bt">
                          <Button
                            icon={<DownloadOutlined />}
                            onClick={() => handleDownload(item.id)}
                          >
                            ดาวน์โหลด
                          </Button>
                          {item.status === "pending" && (
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => handleEditOk(item.id)}
                            >
                              เผยแพร่
                            </Button>
                          )}
                          <Modal
                            title="แก้ไขข้อมูล Import"
                            open={isEditModalOpen}
                            onOk={handleEditOk}
                            onCancel={handleEditCancel}
                          >
                            <div className="import-publish">
                              <p>การเผยแพร่</p>
                              <Radio.Group
                                value={editPublish}
                                onChange={handleEditPublishChange}
                                size="medium"
                              >
                                <Radio.Button value="complete">
                                  เปิดใช้งาน
                                </Radio.Button>
                                <Radio.Button value="pending">
                                  ปิดใช้งาน
                                </Radio.Button>
                              </Radio.Group>
                            </div>
                          </Modal>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showDeleteModal(item.id);
                            }}
                          >
                            ลบไฟล์
                          </Button>
                          <Modal
                            title="ลบข้อมูล"
                            open={isDeleteModalOpen}
                            onOk={handleDeleteOk}
                            onCancel={handleDeleteCancel}
                          ></Modal>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else {
      console.log(admin.operator_id)
      console.log(filteredDataByType)
      // If admin.role is operator, filter the data based on company name
      filteredDataByType = filteredDataByType.filter(
        (item) =>
          item.admin &&
          item.admin.operator_id === admin.operator_id
      );

      return (
        <div className="import-scroll">
          <div className="container">
            <div className="inner-con">
              <style>
                {`
                  * {
                    box-sizing: border-box;
                  }

                  .table-container {
                    border-collapse: collapse;
                    width: 100%;
                    border: 1px solid black;
                    margin-top: 20px;
                  }

                  .table-container th {
                    padding: 15px;
                    text-align: center; /* Align center horizontally */
                    vertical-align: middle; /* Align center vertically */
                    background-color: rgb(239, 147, 108);
                  }

                  .table-container td {
                    padding: 15px;
                    text-align: center; /* Align center horizontally */
                    vertical-align: middle; /* Align center vertically */
                  }

                  .table-container tr:hover {
                    background-color: #f5f5f5;
                  }

                  .inner-con {
                    width: 100%;
                    display: flex;
                  }
                `}
              </style>
              <table className="table-container">
                <thead>
                  <tr>
                    <th>รายการ</th>
                    <th>เครือข่าย</th>
                    <th>ผู้อัปโหลด</th>
                    <th>การเผยแพร่</th>
                    <th>อัปโหลดเมื่อ</th>
                    <th>จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataByType
                    .sort((a, b) => {
                      if (
                        a.status === "pending" &&
                        b.status === "complete"
                      ) {
                        return -1;
                      } else if (
                        a.status === "complete" &&
                        b.status === "pending"
                      ) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .map((item) => (
                      <tr key={item.id}>
                        <td>{item.file_name}</td>
                        <td>
                          {item.operators.company_name}
                        </td>
                        <td>
                          {item.admin.username}
                        </td>

                        <td>
                          {item.status === "pending" ? "ไม่" : "ใช่"}
                        </td>
                        <td>
                          {new Date(item.createDate).toLocaleString()}
                        </td>
                        <td className="import-bt">
                          <Button
                            icon={<DownloadOutlined />}
                            onClick={() => handleDownload(item.id)}
                          >ดาวน์โหลด</Button>
                          {item.status === "pending" && (
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => handleEditOk(item.id)}
                            >
                              เผยแพร่
                            </Button>
                          )}
                          <Modal
                            title="แก้ไขข้อมูล Import"
                            open={isEditModalOpen}
                            onOk={handleEditOk}
                            onCancel={handleEditCancel}
                          >
                            <div className="import-publish">
                              <p>การเผยแพร่</p>
                              <Radio.Group
                                value={editPublish}
                                onChange={handleEditPublishChange}
                                size="medium"
                              >
                                <Radio.Button value="complete">
                                  เปิดใช้งาน
                                </Radio.Button>
                                <Radio.Button value="pending">
                                  ปิดใช้งาน
                                </Radio.Button>
                              </Radio.Group>
                            </div>
                          </Modal>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showDeleteModal(item.id);
                            }}
                          >
                            ลบไฟล์
                          </Button>
                          <Modal
                            title="ลบข้อมูล"
                            open={isDeleteModalOpen}
                            onOk={handleDeleteOk}
                            onCancel={handleDeleteCancel}
                          ></Modal>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  };


  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  return (
    <div className="import-outer">
      <div className="importContainer">
        <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          จัดการข้อมูล Import
        </span>
        <Button type="primary" onClick={showModal}>
          เพิ่มรายการ
        </Button>
        <Modal
          title="Import ไฟล์เข้าสู่ระบบ"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="import-file">
            <p>ไฟล์</p>
            <input
              type="file"
              accept=".xlsx, .xls"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="import-service">
            <p>เครือข่าย</p>
            {admin.role === "operator" ? (
              <div>{admin.organization}</div>
            ) : (
              <select
                value={selectedNetwork}
                onChange={(e) => handleNetworkChange(e.target.value)}
              >
                <option disabled value="">
                  กรุณาเลือกเครือข่าย
                </option>
                {networks.map((network) => (
                  <option key={network.id} value={network.id}>
                    {network.company_name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <p>
            <span class="bold-underline">คําเตือน</span>{" "}
            ท่านกําลังจะอัพโหลดไฟล์แทนที่ข้อมูลเดิม
          </p>
        </Modal>
      </div>
      <div className="info-search">
        <div className="type-bt">
          <Button
            id="disabled"
            onClick={() => handleButtonClick("disabled")}
            type={selectedButton === "disabled" ? "primary" : "default"}
            danger
          >
            คนพิการ
          </Button>
          <Button
            id="student"
            onClick={() => handleButtonClick("student")}
            type={selectedButton === "student" ? "primary" : "default"}
            danger
          >
            นักเรียน
          </Button>
        </div>
        <Search
          placeholder="ค้นหา"
          style={{ width: 200 }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {Table()}
    </div>
  );
};

export default ImportPage;