import React from 'react';
import '../Styles/Sidebar.css'
import nbtcLogo from '../Assets/nbtc-logo.png';
import SidebarFooter from './SidebarFooter';
import SidebarImg from './SidebarImg';

const Sidebar = () => {
    return(
        <div>
            <div className="sidebar-container">
                <div className="sidebar-flex">
                    <div className="logo-brand">
                        <img src={nbtcLogo} className='sidebar-logo'></img>
                        <div className="sidebar-brandName">
                            Your <span style={{ fontWeight: '700' }}>brand</span> 
                        </div>
                    </div>
                    <div className="sidebar-head-sub">
                        <div className="sidebar-heading">
                            Heading
                        </div>
                        <div className="sidebar-subtitle">
                            Subtitle
                        </div>
                    </div>
                </div>
                <SidebarImg/>  
            </div>
            <SidebarFooter/>  
        </div>
    )
}

export default Sidebar