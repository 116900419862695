import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Radio, DatePicker, Form } from "antd";
import moment from "moment";
import axios from "axios";
import { multiStepContext } from "../StepContext";
import "../Styles/TeamPage.css";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const CampaignPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [selectedEditImageUrl, setSelectedEditImageUrl] = useState(null);
    const { admin } = useContext(multiStepContext);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [editStartEndTime, setEditStartEndTime] = useState([]);
    const [editStatus, setEditStatus] = useState(null);
    const [campaignType, setCampaignType] = useState("");
    const [selectedOperator, setSelectedOperator] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [form] = Form.useForm();

    const handleEdit = (id) => {
        setSelectedItemId(id);
    };

    const showEditModal = () => {
        setIsEditModalOpen(true);
    };

    const handleEditOk = async () => {
        try {
            const payload = {
                startEndTime: editStartEndTime,
                status: editStatus
            };

            if (!selectedItemId) {
                console.log("No item selected for editing.");
                return;
            }

            const response = await axios.put(`${apiURL}editCampaign/${selectedItemId}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
            setIsEditModalOpen(false);
            setEditStartEndTime([]);
            setCampaignType("");
            setEditStatus("");
            fetchData();
        } catch (error) {
            console.error('Error updating campaign:', error);
        } finally {
            setEditStartEndTime([]);
            setCampaignType("");
            setEditStatus("");
        }
    };


    const handleEditCancel = () => {
        setIsEditModalOpen(false);
        setEditStartEndTime([]);
        setCampaignType("");
        setEditStatus("");
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiURL}getCampaign`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleUpload = (e) => {
        setSelectedImageUrl(e.target.files[0]);
    };

    const setInputOperator = (e) => {
        setSelectedOperator(e);
    };

    const handleEditUpload = (e) => {
        setSelectedEditImageUrl(e.target.files[0]);
    };

    const retriveData = async () => {
        try {
            const response = await axios.get(`${apiURL}editCampaign/${selectedItemId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            const { campaignStatus, startTime, endTime, type } = response.data;
            console.log(response.data)
            setEditStatus(campaignStatus.description);
            setEditStartEndTime([
                startTime,
                endTime
            ]);
            setCampaignType(type);

            form.setFieldsValue({
                picker: [
                    dayjs(startTime),
                    dayjs(endTime)
                ]
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (isEditModalOpen) {
            retriveData();
        }
    }, [isEditModalOpen]);

    const handleRangePickerChange = (values) => {
        if (values && values.length) {
            setEditStartEndTime(values);
        } else {
            setEditStartEndTime([]);
        }
    };

    const table = () => {
        return (
            <div className="container">
                <div className="inner-con">
                    <style>
                        {`
                            * {
                                box-sizing: border-box;
                            }
                            .table-container {
                                border-collapse: collapse;
                                width: 100%;
                                border: 1px solid black;
                                margin-top: 20px;
                            }
                            .table-container th {
                                padding: 15px;
                                text-align: center; /* Align center horizontally */
                                vertical-align: middle; /* Align center vertically */
                                background-color: rgb(239, 147, 108);
                            }
                            .table-container td {
                                padding: 15px;
                                text-align: center; /* Align center horizontally */
                                vertical-align: middle; /* Align center vertically */
                            }
                            .table-container tr:hover {
                                background-color: #f5f5f5;
                            }
                            .inner-con {
                                width: 100%;
                                display: flex;
                            }
                        `}
                    </style>
                    <table className="table-container">
                        <thead>
                            <tr>
                                <th>ประเภท</th>
                                <th>เวลาเริ่ม</th>
                                <th>เวลาจบ</th>
                                <th>สถานะ</th>
                                <th>จัดการ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(data) && data.length > 0 ? (
                                data.filter(item => item.type.includes(searchValue)).map((item, index) => (
                                    <tr key={item.id || index}>
                                        <td>{item.type || "No Type"}</td>
                                        <td>{item.startTime ? moment(item.startTime).format('DD/MM/YYYY HH:mm') : "No Start Time"}</td>
                                        <td>{item.endTime ? moment(item.endTime).format('DD/MM/YYYY HH:mm') : "No End Time"}</td>
                                        <td>{item.campaignStatus ? item.campaignStatus.description : "No Status"}</td>
                                        <td>
                                            <div className="btn-field">
                                                <Button
                                                    className="btn-edit"
                                                    onClick={() => {
                                                        showEditModal();
                                                        handleEdit(item.id);
                                                    }}
                                                    icon={<EditOutlined />}
                                                >
                                                    แก้ไข
                                                </Button>

                                                <Modal
                                                    title={`แก้ไข - ${campaignType}`}
                                                    open={isEditModalOpen}
                                                    onOk={handleEditOk}
                                                    onCancel={handleEditCancel}
                                                >
                                                    <Form form={form}>
                                                        <Form.Item
                                                            name="picker"
                                                            label="เวลาเริ่ม-จบ"
                                                            style={{ marginTop: "20px" }}
                                                        >
                                                            <RangePicker
                                                                showTime
                                                                format="YYYY/MM/DD HH:mm"
                                                                onChange={handleRangePickerChange}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="สถานะ">
                                                            <Radio.Group
                                                                value={editStatus}
                                                                size="medium"
                                                                onChange={(e) =>
                                                                    setEditStatus(e.target.value)
                                                                }
                                                            >
                                                                <Radio.Button value="เปิด">เปิด</Radio.Button>
                                                                <Radio.Button value="ปิด">ปิด</Radio.Button>
                                                                <Radio.Button value="ปรับปรุง">ปรับปรุง</Radio.Button>
                                                                <Radio.Button value="เต็ม">เต็ม</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Form>
                                                </Modal>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <div className="term-scroll" style={{ padding: "12px" }}>
            <div className="info-header-container">
                <div className="info-header">
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                        สถานะการเปิดลงทะเบียน
                    </span>
                </div>
            </div>
            {table()}
        </div>
    );
};

export default CampaignPage;