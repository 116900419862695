import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Input, Modal, Flex, Radio, Image, Select } from "antd";
import moment from "moment";
import axios from "axios";
import { multiStepContext } from "../StepContext";
import "../Styles/TeamPage.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const TeamPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { Search } = Input;
  const { Option } = Select;
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedEditImageUrl, setSelectedEditImageUrl] = useState(null);
  const [status, setStatus] = useState("true");
  const [editStatus, setEditStatus] = useState("true");
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
  const { admin } = useContext(multiStepContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [editPackageName, setEditPackageName] = useState("");
  const [editPackageDetail, setEditPackageDetail] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [packageName, setPackageName] = useState("");
  const [packageDetail, setPackageDetail] = useState("");
  const editImageUrlInputRef = useRef(null);
  const [tempSelectedStatus, setTempSelectedStatus] = useState(null);
  const [currentBanner, setCurrentBanner] = useState("")

  const [services, setServices] = useState([]);
  const [selectedOperator,setSelectedOperator] = useState('');


  const handleEdit = (id) => {
    setSelectedItemId(id);
  };

  const showEditModal = (userId) => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const fd = new FormData();
      fd.append("packageName", document.getElementById("editPackageName").value);
      fd.append("packageDetail", document.getElementById("editPackageDetail").value);
  
      if (selectedEditImageUrl) {
        fd.append("image", selectedEditImageUrl);
      } else {
        fd.append("image", currentBanner);
      }
  
      fd.append("statusBool", tempSelectedStatus);
  
      console.log([...fd.entries()]);
  
      if (!selectedItemId) {
        console.log("No item selected for editing.");
        return;
      }
  
      const response = await axios.put(
        `${apiURL}editPackage/${selectedItemId}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      setIsEditModalOpen(false);
      console.log(response);
  
      setEditPackageDetail("");
      setEditPackageName("");
      setSelectedEditImageUrl(null);
      if (editImageUrlInputRef.current) {
        editImageUrlInputRef.current.value = "";
      }
      setStatus(tempSelectedStatus);
      fetchData();
    } catch (error) {
      console.log(error);
      setIsModalOpen(false);
    } finally {
      setTempSelectedStatus(null);
    }
  };
  

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiURL}getPackage`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getService = async () => {
    try {
      const response = await axios.get(`${apiURL}getOperator`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
      }});
      setServices(response.data);

    } catch (error) {
      console.error("Error get service data:", error);
    }
  }

  const handleOk = async () => {
    try {
      const fd = new FormData();
      fd.append("image", selectedImageUrl);
      fd.append("name", selectedImageUrl.name);
      fd.append("operatorId", selectedOperator);
      fd.append("packageName", document.getElementById("packageName").value);
      fd.append(
        "packageDetail",
        document.getElementById("packageDetail").value
      );
      fd.append("statusBool", status);

      console.log([...fd.entries()]);

      const response = await axios.post(`${apiURL}addPackage`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setIsModalOpen(false);
      console.log(response);
      setPackageDetail("");
      setPackageName("");
      setSelectedImageUrl(null);
      const fileInput = document.getElementById("imageUrlInput");
      if (fileInput) {
        fileInput.value = "";
      }
      setStatus(tempSelectedStatus);
      fetchData()
    } catch (error) {
      console.log(error);
      setIsModalOpen(false);
    } finally {
      setTempSelectedStatus(null);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    fetchData();
    getService();
  }, [token]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiURL}deletePackage/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setData(data.filter((item) => item.id !== id));
      console.log("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleUpload = (e) => {
    setSelectedImageUrl(e.target.files[0]);
  };

  const setInputOperator = (e) => {
    setSelectedOperator(e)
  }

  const handleEditUpload = (e) => {
    setSelectedEditImageUrl(e.target.files[0]);
  };

  const showDeleteModal = (id) => {
    setItemToDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
    handleDelete(itemToDeleteId);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const filteredData = data.filter((item) =>
    item.packageName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const retriveData = async () => {
    console.log(selectedItemId)
    try {
      const response = await axios.get(`${apiURL}admin/editPackage/getPackageData/${selectedItemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setEditPackageDetail(response.data.data.packageDetail)
      setEditPackageName(response.data.data.packageName)
      setCurrentBanner(response.data.data.Banners)
      setTempSelectedStatus(String(response.data.data.status))
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      retriveData();
    }
  }, [selectedItemId]);

  const table = () => {
    return (
      <div className="container">
        <div className="inner-con">
          <style>
            {`
              * {
                box-sizing: border-box;
              }
  
              .table-container {
                border-collapse: collapse;
                width: 100%;
                border: 1px solid black;
                margin-top: 20px;
              }
  
              .table-container th {
                padding: 15px;
                text-align: center; /* Align center horizontally */
                vertical-align: middle; /* Align center vertically */
                background-color: rgb(239, 147, 108);
              }
  
              .table-container td {
                padding: 15px;
                text-align: center; /* Align center horizontally */
                vertical-align: middle; /* Align center vertically */
              }
  
              .table-container tr:hover {
                background-color: #f5f5f5;
              }
  
              .inner-con {
                width: 100%;
                display: flex;
              }
            `}
          </style>
          <table className="table-container">
            <thead>
              <tr>
                <th>ชื่อแพ็คเกจ</th>
                <th>เครือข่าย</th>
                <th>สถานะ</th>
                <th>วันที่สร้าง</th>
                <th>จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>{item.id}</td>
                  <td>{item.packageName}</td>
                  <td>{item.operators.company_name}</td>
                  <td>{item.status ? "เปิดใช้งาน" : "ปิดใช้งาน"}</td>
                  <td>{new Date(item.createDate).toLocaleString()}</td>
                  <td>
                    <div className="btn-field">
                      <Button
                        className="btn-edit"
                        onClick={() => {
                          showEditModal(item.id);
                          handleEdit(item.id);
                        }}
                        icon={<EditOutlined />}
                      >
                        แก้ไข
                      </Button>

                      <Modal
                        title="แก้ไขแพ็คเกจ"
                        open={isEditModalOpen}
                        onOk={handleEditOk}
                        onCancel={handleEditCancel}
                      >
                        <div className="modal-banner">
                          <p  style={{width: "30%" }}>ชื่อแพ็คเกจ</p>
                          <textarea
                            id="editPackageName"
                            value={editPackageName}
                            onChange={(e) =>
                              setEditPackageName(e.target.value)
                            }
                            style={{ marginLeft: "-10px" , width: "100%" }}
                          ></textarea>
                        </div>
                        <h3>เงื่อนไขการใช้งาน</h3>
                        <textarea
                          id="editPackageDetail"
                          value={editPackageDetail}
                          onChange={(e) =>
                            setEditPackageDetail(e.target.value)
                          }
                          style={{ width: "100%", height: "100px", maxWidth: "100%" }}
                        ></textarea>
                        <h3>แบนเนอร์แพ็คแกจ</h3>
                        <div className="current-banner">
                          <p>ภาพแบนเนอร์ปัจจุบัน</p>
                          <Image src={currentBanner} style={{width: "250px"}} />
                        </div>
                        <div className="modal-banner">
                          <p>ภาพแบนเนอร์</p>
                          <input
                            ref={editImageUrlInputRef}
                            id="editImageUrlInput"
                            type="file"
                            onChange={handleEditUpload}
                            accept="image/*"
                            style={{marginLeft: "20px" }}
                          />
                        </div>
                        <div className="modal-banner">
                          <p>สถานะ</p>
                          <Radio.Group
                            value={tempSelectedStatus}
                            defaultValue="a"
                            size="medium"
                            onChange={(e) =>
                              setTempSelectedStatus(e.target.value)
                            }
                            style={{marginLeft: "56px" }}
                          >
                            <Radio.Button value="true">
                              เปิดใช้งาน
                            </Radio.Button>
                            <Radio.Button value="false">
                              ปิดใช้งาน
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                        <div className="modal-banner">
                          <p>วันที่แก้ไข</p>
                          <p style={{marginLeft: "30px" }}>{currentDate}</p>
                        </div>
                        <input type="hidden" id="imageEditUrlInput" />
                      </Modal>
                      { admin.role === 'admin' ? <Button
                        className="btn-delete"
                        onClick={() => showDeleteModal(item.id)}
                        icon={<DeleteOutlined />} > ลบ
                         </Button>
                        : '' 
                      }
                      <Modal
                        title="ต้องการลบไฟล์หรือไม่"
                        open={isDeleteModalOpen}
                        onOk={handleDeleteOk}
                        onCancel={handleDeleteCancel}
                      ></Modal>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  return (
    <div className="term-scroll" style={{ padding: "12px" }}>
      <div className="info-header-container">
        <div className="info-header">
          <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            จัดการแพ็คเกจ
          </span>
          {admin.role === 'admin' ? <Button type="primary" onClick={showModal}>เพิ่มรายการ</Button> : ''}
          <Modal
            title="เพิ่มแพ็คเกจ"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="modal-banner">
              <p  style={{width: "20%" }}>เครือข่าย</p>
              <Select id='operator' style={{width:150}} onChange={(e)=> setInputOperator(e)}>
              { services.map((service) => (
                  <Option
                    key={service.id}
                    value={service.id}
                  >
                    {service.company_name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="modal-banner">
              <p style={{width: "30%" }}>ชื่อแพ็คเกจ</p>
              <textarea
                id="packageName"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
                style={{ marginLeft: "-10px" , width: "100%" }}
              ></textarea>
            </div>
            <h3>เงื่อนไขการใช้งาน</h3>
            <textarea
              id="packageDetail"
              value={packageDetail}
              onChange={(e) => setPackageDetail(e.target.value)}
              style={{ width: "100%", height: "100px", maxWidth: "100%" }}
            ></textarea>
            <h3>แบนเนอร์แพ็คแกจ</h3>
            <div className="modal-banner">
              <p>ภาพแบนเนอร์</p>
              <input
                id="imageUrlInput"
                type="file"
                onChange={handleUpload}
                accept="image/*"
                style={{marginLeft: "20px" }}
              />
            </div>
            <div className="modal-banner">
              <p>สถานะ</p>
              <Radio.Group
                value={tempSelectedStatus}
                defaultValue="a"
                size="medium"
                onChange={(e) => setTempSelectedStatus(e.target.value)}
                style={{marginLeft: "56px" }}
              >
                <Radio.Button value="true">เปิดใช้งาน</Radio.Button>
                <Radio.Button value="false">ปิดใช้งาน</Radio.Button>
              </Radio.Group>
            </div>
            <div className="modal-banner">
              <p>วันที่สร้าง</p>
              <p style={{marginLeft: "30px" }}>{currentDate}</p>
            </div>
            <input type="hidden" id="imageUrlInput" />
          </Modal>
        </div>
        <div className="info-search">
          <Search
            placeholder="ค้นหา"
            style={{ width: 200 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      {table()}
    </div>
  );
};

export default TeamPage;