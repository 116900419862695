import React, { useState, useContext, useEffect } from 'react';
import data from './Province.json';
import { GeoJSON } from 'react-leaflet';
import axios from "axios";
import { multiStepContext } from "../StepContext";

const Province = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const apiURL = process.env.REACT_APP_API_URL;

  const [province, setProvince] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [readyToPlot, setReadyToPlot] = useState(false)

  useEffect(() => {
    if (!dataFetched) { 
      fetchData();
    }
  }, [dataFetched]);  

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiURL}dashboard/getProvinceData`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setProvince(response.data.result);
      setDataFetched(true); 

      data.features.forEach((feature, index) => {
        const provinceData = response.data.result.find(item => item.province.id == feature.properties.ID);
        const provinceCount = provinceData ? provinceData.count : 0; 
        feature.properties.count = provinceCount;
      });

      setReadyToPlot(true)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const geoStyle = (feature) => {
    const region = feature.properties.DENSITY
    const mapColor = feature.properties.count
    // console.log(mapColor)
    if(mapColor <= 0){
      return{
        weight: 1,
        color: '#dcdfef'
      }
    }
    else if(mapColor > 1 && mapColor <= 100){
      return{
        weight: 1,
        color: 'blue',
        fillOpacity: 0.3
      }
    }
    else if(mapColor > 100 && mapColor <= 500){
      return{
        weight: 1,
        color: 'blue',
        fillOpacity: 0.5
      }
    }
    else if(mapColor > 500 && mapColor <= 1000){
      return{
        weight: 1,
        color: 'blue',
        fillOpacity: 0.7
      }
    }
    else if(mapColor > 1000){
      return{
        weight: 1,
        color: 'blue',
        fillOpacity: 0.9
      }
    }
  }

  const handleEachFeature = (feature, layer) => {
    if(feature.properties.count > 0){
      const count = feature.properties.count;
      layer.bindTooltip(`${feature.properties.ADM1_TH} - จำนวนผู้ลงทะเบียน: ${count}`, {
        direction: 'center',
      });
    }
  };
  

  return readyToPlot && data && <GeoJSON 
    data={data} 
    style={geoStyle}
    onEachFeature={handleEachFeature}
    />
}

export default Province