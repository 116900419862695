import React, { useContext } from 'react';
import './App.css';
import Sidebar from '../src/Components/Sidebar'
import SignInPage from './Pages/SignInPage';
import PasswordPage from './Pages/PasswordPage';
import BackendPage from './Pages/BackendPage';
import MobileHeader from '../src/Components/MobileHeader';
import MobileFooter from '../src/Components/MobileFooter';
import { multiStepContext } from './StepContext';

function App() {

  const { currentStep, finalData } = useContext(multiStepContext);
  function showStep(step){
      switch(step){
        case 1:
          return(
            <div className="admin-page">
              <div className="sidebar-main">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
              </div>
              <div className='admin-field'>
                <SignInPage/>
              </div>
              <div className="footer">
                  <MobileFooter/>
              </div>
            </div>
          )
        case 2:
          return(
            <div className="admin-page">
              <div className="sidebar-main">
                <Sidebar/>
              </div>
              <div className="header">
                <MobileHeader/>
              </div>
              <div className='admin-field'>
                <PasswordPage/>
              </div>
              <div className="footer">
                <MobileFooter/>
              </div>
            </div>
          )
        case 3:
          return(
            <div className='admin-field'>
                <BackendPage/>
            </div>
          )
      }
  }

  return (
    <div className=''>
      {showStep(currentStep)}
    </div>
  );
}

export default App;
