import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const StackBarChart = () => {
  const [selectedDirection, setSelectedDirection] = useState('north');

  const handleDirectionChange = (event) => {
    setSelectedDirection(event.target.value);
  };

  const getDataForDirection = (direction) => {
    switch (direction) {
      case 'north':
        return {
          labels: ['เชียงใหม่', 'ลําปาง', 'เชียงราย'],
          disable: [9, 6, 3],
          student: [8, 5, 2],
        };
      case 'south':
        return {
          labels: ['ชุมพร', 'ตรัง', 'นครศรีธรรมราช'],
          disable: [8, 6, 4],
          student: [7, 5, 3],
        };
      case 'east':
        return {
          labels: ['ระยอง', 'ตราด', 'จันทบุรี'],
          disable: [7, 5, 3],
          student: [6, 4, 2],
        };
      case 'west':
        return {
          labels: ['ตาก', 'กาญจนบุรี', 'ราชบุรี'],
          disable: [6, 5, 1],
          student: [5, 4, 2],
        };
      case 'central':
        return {
          labels: ['กรุงเทพ', 'นครปฐม', 'สมุทรปราการ'],
          disable: [9, 6, 4],
          student: [8, 5, 3],
        };
      default:
        return {
          labels: ['Mon', 'Tue', 'Wed'],
          disable: [0, 0, 0],
          student: [0, 0, 0],
        };
    }
  };

  const data = {
    labels: getDataForDirection(selectedDirection).labels,
    datasets: [
      {
        label: 'Disable',
        data: getDataForDirection(selectedDirection).disable,
        backgroundColor: '#61DBFB',
      },
      {
        label: 'Student',
        data: getDataForDirection(selectedDirection).student,
        backgroundColor: '#darkblue',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'flex-end' }}> {/* Style to flex-end */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <select value={selectedDirection} onChange={handleDirectionChange}>
          <option value="north">North</option>
          <option value="south">South</option>
          <option value="east">East</option>
          <option value="west">West</option>
          <option value="central">Central</option>
        </select>
      </div>
      <Bar data={data} options={options}></Bar>
    </div>
  );
};

export default StackBarChart;
