import React, {useContext} from 'react';
import '../Styles/PasswordPage.css'
import { multiStepContext } from '../StepContext';

const PasswordPage = () => {

  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const handlePassword = () => {
    setStep(1);
    console.log(userData)
  }

  return (
    <div className="pw-container">
        <div className="pw-info">
              กู้คืนรหัสผ่านด้วยอีเมล
              <br/>
              <span style={{ fontSize: '16' }}>Reset Password</span> 
        </div>
        <div className='pw-input'>
          <div className="pw-id-con">
              อีเมล
              <input type="text" className="pw-status" value={userData['email']} onChange={(e)=>setUserData({...userData, "email": e.target.value})}></input>
          </div>
        </div>
        <div className='pw-next-bt'>
            <button onClick={handlePassword} className="pw-condition-button">
                ยืนยัน
            </button>
        </div>
    </div>
  );
};

export default PasswordPage;
