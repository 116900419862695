import React, { useEffect, useState } from "react";
import "../Styles/Dashboard.css";
import { Chart as ChartJS } from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import StackBarChart from "../Components/StackBarChart";
import CalendarImage from "../Assets/calendar.png";
import Group from "../Assets/group.png";
import Student from "../Assets/student.png";
import Disable from "../Assets/wheelchair.png";
import Map from "../Components/Map.js";
import { multiStepContext } from "../StepContext";
import axios from "axios";
ChartJS.register(ChartDataLabels);

const Dashboard = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [studentDegree, setStudentDegree] = useState([]);
  const [DisabledType, setDisableType] = useState([]);
  const [simSub, setSimSub] = useState([]);
  const [operator, setOperator] = useState([]);
  const [aisPercent, setAisPercent] = useState(0)
  const [dtacPercent, setDtacPercent] = useState(0)
  const [ntPercent, setNtPercent] = useState(0)

  const retriveMarketShare = async () => {
    try {
      const response = await axios.get(`${apiURL}dashboard/MarketShare`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setOperator(response.data.result);

      const totalMarket =
        response.data.result[0].disabled +
        response.data.result[1].disabled +
        response.data.result[2].disabled +
        response.data.result[0].student +
        response.data.result[1].student +
        response.data.result[2].student;

      setAisPercent(((response.data.result[0].disabled + response.data.result[0].student) / totalMarket) * 100);

      setDtacPercent(((response.data.result[1].disabled + response.data.result[1].student) / totalMarket) * 100);

      setNtPercent(((response.data.result[2].disabled + response.data.result[2].student) / totalMarket) * 100);



    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const retriveSubOption = async () => {
    try {
      const response = await axios.get(`${apiURL}dashboard/SimSubOption`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSimSub(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const retriveStudentDegree = async () => {
    try {
      const response = await axios.get(`${apiURL}dashboard/getStudentDegree`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setStudentDegree(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const retriveDisableType = async () => {
    try {
      const response = await axios.get(`${apiURL}dashboard/getDisabledType`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setDisableType(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    retriveStudentDegree();
    retriveDisableType();
    retriveSubOption();
    retriveMarketShare();
  }, []);

  if (!studentDegree || studentDegree.length === 0) {
    return null;
  }

  return (
    <div className="dashboard-scrollable">
      <div className="dashboard-con">
        <span
          style={{ fontSize: "1.2rem", fontWeight: "bold", marginLeft: "10px" }}
        >
          Dashboard
        </span>
        <div className="top-dashboard">
          <div className="date">
            <div className="chart-header-top-date">Date Event Period</div>
            <span
              className="date-text"
              style={{ fontSize: "1.3rem", fontWeight: "bold" }}
            >
              <img
                src={CalendarImage}
                alt="Calendar"
                style={{
                  marginRight: "5px",
                  width: "50px",
                  height: "50px",
                }}
              />
              01 Jan, 2024 - 31 DEC, 2024
            </span>
          </div>
          <div className="amount">
            <div className="total">
              <span
                className="total-text"
                style={{ fontSize: "2rem", fontWeight: "bold" }}
              >
                <img
                  src={Group}
                  alt="Calendar"
                  style={{
                    marginRight: "5px",
                    width: "60px",
                    height: "60px",
                  }}
                />
                {
                  operator.length != 0 ?
                    (operator[0].disabled +
                      operator[1].disabled +
                      operator[2].disabled +
                      operator[0].student +
                      operator[1].student +
                      operator[2].student) : 0
                }
              </span>
              <div className="chart-header-top-total">
                Total Number of Participation
              </div>
            </div>
            <div className="disable">
              <span
                className="student-text"
                style={{ fontSize: "2rem", fontWeight: "bold" }}
              >
                <img
                  src={Student}
                  alt="Student"
                  style={{
                    marginRight: "5px",
                    width: "60px",
                    height: "60px",
                  }}
                />
                {
                  operator.length != 0 ?
                    (operator[0].student +
                      operator[1].student +
                      operator[2].student) : 0
                }
              </span>
              <div className="chart-header-top-student">Student Number</div>
            </div>
            <div className="student">
              <span
                className="disable-text"
                style={{ fontSize: "2rem", fontWeight: "bold" }}
              >
                <img
                  src={Disable}
                  alt="disable"
                  style={{
                    marginRight: "5px",
                    width: "60px",
                    height: "60px",
                  }}
                />
                {
                  operator.length != 0 ?
                    (operator[0].disabled +
                      operator[1].disabled +
                      operator[2].disabled) : 0}
              </span>
              <div className="chart-header-top-disable">Eligibility Number</div>
            </div>
          </div>
        </div>
        <div className="main-dashboard">
          <div className="main-top">
            <div className="chart1">
              <div className="chart-header-1">
                SIM card subscription options
              </div>
              <div
                style={{ width: "500px", height: "500px", marginTop: "-60px" }}
              >
                <Pie
                  data={{
                    labels: [
                      "ผู้พิการ - รับซิมการ์ดใหม่",
                      "ผู้พิการ - ใช้เบอร์โทรเดิม",
                      "นักเรียน - รับซิมการ์ดใหม่",
                      "นักเรียน - ใช้เบอร์โทรเดิม",
                    ],
                    datasets: [
                      {
                        label: "จำนวนผู้ลงทะเบียน",
                        data: [
                          simSub.NewNumDis,
                          simSub.OldNumDis,
                          simSub.NewNumStu,
                          simSub.OldNumStu,
                        ],
                        backgroundColor: [
                          "#ECBE64",
                          "#F2DEAB",
                          "#CAABF2",
                          "#957BB6",
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: "right",
                        labels: {
                          boxWidth: 15,
                          usePointStyle: false,
                          generateLabels: function (chart) {
                            const labels = chart.data.labels;
                            const datasets = chart.data.datasets;
                            const newLabels = [];
                            for (let i = 0; i < labels.length; i++) {
                              newLabels.push({
                                text: `${labels[i]} - ${datasets[0].data[i]}`,
                                fillStyle: datasets[0].backgroundColor[i],
                                hidden: false,
                                index: i,
                                datasetIndex: 0,
                              });
                            }
                            const newCount =
                              chart.data.datasets[0].data[0] +
                              chart.data.datasets[0].data[1];
                            const sameCount =
                              chart.data.datasets[0].data[2] +
                              chart.data.datasets[0].data[3];
                            newLabels.push({
                              text: `ผู้พิการทั้งหมด: ${newCount}`,
                              fillStyle: "rgba(0, 0, 0, 0.7)",
                              hidden: false,
                              index: labels.length,
                              datasetIndex: 0,
                            });
                            newLabels.push({
                              text: `นักเรียนทั้งหมด: ${sameCount}`,
                              fillStyle: "rgba(0, 0, 0, 0.7)",
                              hidden: false,
                              index: labels.length + 1,
                              datasetIndex: 0,
                            });
                            return newLabels;
                          },
                        },
                      },
                      datalabels: {
                        color: "black",
                        formatter: (value, context) => {
                          if (value > 0) {
                            const dataset = context.chart.data.datasets[0];
                            const total = dataset.data.reduce(
                              (acc, val) => acc + val,
                              0
                            );
                            const percentage =
                              ((value / total) * 100).toFixed(1) + "%";
                            return `${percentage}\n(${dataset.data[context.dataIndex]
                              })`;
                          } else {
                            return '';
                          }
                        },
                        anchor: "center",
                        align: "center",
                        offset: 4,
                        font: {
                          size: 15,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="chart2">
              <div className="chart-header-1">
                Popular SIM card pickup zones
              </div>
              <Map />
              <div className="map-legend">
                <div className="legend1">
                  <div className="colorBox1"></div>
                  0-50
                </div>
                <div className="legend2">
                  <div className="colorBox2"></div>
                  50-100
                </div>
                <div className="legend3">
                  <div className="colorBox3"></div>
                  100-500
                </div>
                <div className="legend4">
                  <div className="colorBox4"></div>
                  500-1000
                </div>
                <div className="legend5">
                  <div className="colorBox5"></div>
                  1000+
                </div>
              </div>
            </div>
          </div>
          <div className="main-bottom">
            <div className="chart3">
              <div className="chart-header-1">Student Degree</div>
              <div
                style={{ width: "450px", height: "450px", marginTop: "-25px" }}
              >
                <Pie
                  data={{
                    labels: [
                      `มัธยม 6   ${studentDegree[5]?.count || 0} คน`,
                      `มัธยม 5   ${studentDegree[4]?.count || 0} คน`,
                      `มัธยม 4   ${studentDegree[3]?.count || 0} คน`,
                      `มัธยม 3   ${studentDegree[2]?.count || 0} คน`,
                      `มัธยม 2   ${studentDegree[1]?.count || 0} คน`,
                      `มัธยม 1   ${studentDegree[0]?.count || 0} คน`,
                      `ประถม 6  ${studentDegree[11]?.count || 0} คน`,
                      `ประถม 5  ${studentDegree[10]?.count || 0} คน`,
                      `ประถม 4  ${studentDegree[9]?.count || 0} คน`,
                      `ประถม 3  ${studentDegree[8]?.count || 0} คน`,
                      `ประถม 2  ${studentDegree[7]?.count || 0} คน`,
                      `ประถม 1  ${studentDegree[6]?.count || 0} คน`,
                    ],
                    datasets: [
                      {
                        label: "จำนวน",
                        data: [
                          studentDegree[5]?.count || 0,
                          studentDegree[4]?.count || 0,
                          studentDegree[3]?.count || 0,
                          studentDegree[2]?.count || 0,
                          studentDegree[1]?.count || 0,
                          studentDegree[0]?.count || 0,
                          studentDegree[11]?.count || 0,
                          studentDegree[10]?.count || 0,
                          studentDegree[9]?.count || 0,
                          studentDegree[8]?.count || 0,
                          studentDegree[7]?.count || 0,
                          studentDegree[6]?.count || 0,
                        ],
                        backgroundColor: [
                          "#DE425B",
                          "#DF6453",
                          "#E7855C",
                          "#ECA56D",
                          "#F1C387",
                          "#F7DFA6",
                          "#E5E6AC",
                          "#CAD490",
                          "#ADC276",
                          "#8EB15D",
                          "#6DA046",
                          "#488F31",
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: "right",
                        labels: {
                          boxWidth: 10,
                          usePointStyle: false,
                          generateLabels: function (chart) {
                            const labels = chart.data.labels;
                            const datasets = chart.data.datasets;
                            const newLabels = [];
                            for (let i = 0; i < labels.length; i++) {
                              newLabels.push({
                                text: labels[i],
                                fillStyle: datasets[0].backgroundColor[i],
                                hidden: false,
                                index: i,
                                datasetIndex: 0,
                              });
                            }
                            return newLabels;
                          },
                        },
                      },
                      datalabels: {
                        color: "black",
                        display: true,
                        formatter: (value, context) => {
                          if (value > 0) {
                            const dataset = context.chart.data.datasets[0];
                            const total = dataset.data.reduce(
                              (acc, val) => acc + val,
                              0
                            );
                            const percentage =
                              ((value / total) * 100).toFixed(1) + "%";
                            return percentage;
                          } else {
                            return '';
                          }
                        },
                        anchor: "end",
                        align: "start",
                        offset: 5,
                        font: {
                          size: 12,
                        },
                      },
                    },
                    aspectRatio: 1,
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
            <div className="chart4">
              <div className="chart-header-1">Disabled Type</div>
              <div className="chart4-content">
                <div
                  style={{
                    width: "50%",
                    // height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "210px", height: "210px" }}>
                    <Pie
                      data={{
                        labels: ["Type-1", "Type-2", "Type-3", "Type-4", "Type-5", "Type-6", "Type-7"],
                        datasets: [
                          {
                            label: "จำนวน",
                            data: DisabledType.length != 0 ?
                              [DisabledType[0].count,
                              DisabledType[1].count,
                              DisabledType[2].count,
                              DisabledType[3].count,
                              DisabledType[4].count,
                              DisabledType[5].count,
                              DisabledType[6].count] : [0, 0, 0, 0, 0, 0, 0]
                            ,
                            backgroundColor: [
                              "#003F5C",
                              "#58508D",
                              "#BC5090",
                              "#FFA600",
                              "#FF6361",
                              "#58508B",
                              "#FFA07A"
                            ],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                          datalabels: {
                            color: "white",
                            display: true,
                            labels: {
                              title: {
                                font: {
                                  weight: "bold",
                                },
                              },
                            },
                            formatter: (value, context) => {
                              if (value > 0) {
                                const dataset = context.chart.data.datasets[0];
                                const total = dataset.data.reduce(
                                  (acc, val) => acc + val,
                                  0
                                );
                                const percentage =
                                  ((value / total) * 100).toFixed(1) + "%";
                                return `${context.chart.data.labels[context.dataIndex]
                                  } \n (${dataset.data[context.dataIndex]
                                  } คน)\n${percentage}`;
                              } else {
                                return '';
                              }
                            },

                            anchor: "end",
                            align: "start",
                            offset: 6,
                            font: {
                              size: 9,
                              textAlign: "center",
                            },
                          },
                        },
                        aspectRatio: 1,
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                </div>
                <table className="chart4-table">
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#003F5C" }}>Type1</td>
                      <td style={{ backgroundColor: "#00699A" }}>ผู้พิการทางการมองเห็น</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#58508D" }}>Type2</td>
                      <td style={{ backgroundColor: "#887DD2" }}>ผู้พิการทางการได้ยินหรือสื่อความหมาย</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#BC5090" }}>Type3</td>
                      <td style={{ backgroundColor: "#FF74C6" }}>ผู้พิการทางการเคลื่อนไหวหรือทางร่างกาย</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#FFA600" }}>Type4</td>
                      <td style={{ backgroundColor: "#FFC700" }}>พิการด้านจิตใจหรือพฤติกรรม</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#FF6361" }}>Type5</td>
                      <td style={{ backgroundColor: "#FF9994" }}>พิการทางสติปัญญา</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#58508B" }}>Type6</td>
                      <td style={{ backgroundColor: "#8977C5" }}>พิการทางการเรียนรู้</td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "#FFA07A" }}>Type7</td>
                      <td style={{ backgroundColor: "#FFDAB9" }}>พิการทางออทิสติก</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="main-last">
            <div className="chart5">
              <div className="chart-header-1">Market Share</div>
              <div style={{ width: "250px", height: "250px" }}>
                <Pie
                  data={{
                    labels: ["AIS", "NT", "TRUE/DTAC"],
                    datasets: [
                      {
                        label: "จำนวน",
                        data:
                          operator.length != 0 ? [
                            operator[0].total,
                            operator[2].total,
                            operator[1].total] : [0, 0, 0]
                        ,
                        backgroundColor: ["#E12F2F", "#E3CB4C", "#6BCE70"],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        color: "white",
                        display: true,
                        formatter: (value, context) => {
                          if (value > 0) {
                            const dataset = context.chart.data.datasets[0];
                            const total = dataset.data.reduce(
                              (acc, val) => acc + val,
                              0
                            );
                            const percentage =
                              ((value / total) * 100).toFixed(1) + "%";
                            return `${context.chart.data.labels[context.dataIndex]
                              }\n(${dataset.data[context.dataIndex]
                              } คน)\n${percentage}`;
                          } else {
                            return '';
                          }
                        },

                        align: "center",
                        offset: 10,
                        font: {
                          size: 12,
                          weight: "bold",
                        },
                      },
                    },
                    aspectRatio: 1,
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
            <div className="chart6">
              <div className="chart-header-1">Market Share</div>
              <div className="table-con">
                <div className="table-data">
                  <table className="table2">
                    <thead>
                      <tr>
                        <th colSpan="2" style={{ border: "none" }}></th>
                        <th
                          colSpan="1"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          Total Number of Participation
                        </th>
                        <th
                          colSpan="1"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          Student Number
                        </th>
                        <th
                          colSpan="1"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          Eligibility Number
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#6BCE70",
                            borderTopLeftRadius: "10px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          AIS
                        </td>
                        <td
                          style={{
                            backgroundColor: "#50AD54",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {(Math.round(aisPercent * 100) / 100)}%
                        </td>
                        <td
                          style={{
                            backgroundColor: "#AEBBF9",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? (operator[0].student + operator[0].disabled) : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#FAE1A0",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[0].student : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#E0AFF9",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[0].disabled : 0}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#EB5555",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          TRUE/DTAC
                        </td>
                        <td
                          style={{
                            backgroundColor: "#E12F2F",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {(Math.round(dtacPercent * 100) / 100)}%
                        </td>
                        <td
                          style={{
                            backgroundColor: "#AEBBF9",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? (operator[1].student + operator[1].disabled) : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#FAE1A0",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[1].student : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#E0AFF9",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[1].disabled : 0}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#FFE55C",
                            borderBottomLeftRadius: "10px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          NT
                        </td>
                        <td
                          style={{
                            backgroundColor: "#E3CB4C",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {(Math.round(ntPercent * 100) / 100)}%
                        </td>
                        <td
                          style={{
                            backgroundColor: "#AEBBF9",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? (operator[2].student + operator[2].disabled) : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#FAE1A0",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[2].student : 0}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#E0AFF9",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {operator.length != 0 ? operator[2].disabled : 0}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
