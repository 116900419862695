import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Radio } from "antd";
import axios from "axios";
import "../Styles/ManageUserPage.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ManageUserPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { Search } = Input;
  const [data, setData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userIdToEdit, setUserIdToEdit] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedEditService, setSelectedEditService] = useState("");

  const retriveData = async () => {
    try {
      const response = await axios.get(`${apiURL}admin/getCurrentData/${userIdToEdit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.message)
      setEditName(response.data.message.firstName)
      setEditLastName(response.data.message.lastName)
      setEditUserName(response.data.message.username)
      setSelectedEditRole(response.data.message.role)
      setSelectedEditService(response.data.message.organization)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiURL}getAllUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    axios
      .get(`${apiURL}getOperator`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }})
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const showDeleteModal = (userId) => {
    setIsDeleteModalOpen(true);
    setUserIdToDelete(userId);
  };

  const handleDeleteOk = async () => {
    try {
      const deleteURL = `${apiURL}deleteUser/${userIdToDelete}`;
      const response = await axios.delete(deleteURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("User deleted:", response.data);
      setIsDeleteModalOpen(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (userIdToEdit) {
      retriveData();
    }
  }, [userIdToEdit]);
  
  useEffect(() => {
    fetchData();
  }, [token]);

  const [searchValue, setSearchValue] = useState("");

  const filteredData = data.filter((item) =>
    item.username.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleEditServiceChange = (e) => {
    setSelectedEditService(e.target.value);
    if (selectedEditRole !== "operator") {
      setSelectedEditService("");
    }
  };

  const Table = () => {
    return (
      <div className="container">
        <div className="inner-con">
          <style>
            {`
              * {
                box-sizing: border-box;
              }
  
              .table-with-gap {
                border-collapse: collapse;
                width: 100%;
                border: 1px solid black;
              }
  
              .table-with-gap th {
                padding: 15px;
                text-align: center; /* Align center horizontally */
                vertical-align: middle; /* Align center vertically */
                background-color: rgb(239, 147, 108);
                color: black;
              }
  
              .table-with-gap td {
                padding: 15px;
                text-align: center; /* Align center horizontally */
                vertical-align: middle; /* Align center vertically */
              }
  
              .table-with-gap tr:hover {
                background-color: #f5f5f5;
              }
  
              .inner-con {
                width: 100%;
                display: flex;
              }
            `}
          </style>
          <table className="table-with-gap">
            <thead className="col-header">
              <tr>
                <th>ลําดับ</th>
                <th>ชื่อผู้ใช้งาน</th>
                <th>ชื่อ-นามสกุล</th>
                <th>บทบาท</th>
                <th>เครือข่าย</th>
                <th>จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.username}</td>
                  <td>{`${user.firstName || ""} ${user.lastName || ""}`}</td>
                  <td>{user.role}</td>
                  <td>{user.organization}</td>
                  <td className="import-bt">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => showEditModal(user.id)}
                    >
                      แก้ไขผู้ใช้งาน
                    </Button>
                    <Modal
                      title="แก้ไขข้อมูลผู้ใช้งาน"
                      open={isEditModalOpen}
                      onOk={handleEditOk}
                      onCancel={handleEditCancel}
                    >
                      <div className="name">
                        <p>ชื่อ</p>
                        <input
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                        ></input>
                      </div>
                      <div className="lastname">
                        <p>นามสกุล</p>
                        <input
                          value={editLastName}
                          onChange={(e) => setEditLastName(e.target.value)}
                        ></input>
                      </div>
                      <div className="username">
                        <p>ซื่อผู้ใช้งาน</p>
                        <input
                          value={editUserName}
                          onChange={(e) => setEditUserName(e.target.value)}
                        ></input>
                      </div>
                      <div className="password">
                        <p>รหัสผ่าน</p>
                        <input
                          value={editPassword}
                          onChange={(e) => setEditPassword(e.target.value)}
                        ></input>
                      </div>
                      <div className="role">
                        <p>บทบาท</p>
                        <Radio.Group
                          value={selectedEditRole}
                          onChange={(e) => setSelectedEditRole(e.target.value)}
                        >
                          <Radio value="admin">ผู้ดูแลระบบ</Radio>
                          <Radio value="staff">เจ้าหน้าที่ กสทช.</Radio>
                          <Radio value="operator">โอเปอร์เรเตอร์</Radio>
                        </Radio.Group>
                      </div>
                      {selectedEditRole === "operator" && (
                        <div className="service">
                          <p>เครือข่าย</p>
                          <Radio.Group
                            value={selectedEditService}
                            onChange={handleEditServiceChange}
                            disabled={selectedEditRole !== "operator"}
                          >
                            {services.map((service) => (
                              <Radio
                                key={service.id}
                                value={service.company_name}
                              >
                                {service.company_name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </div>
                      )}
                    </Modal>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        showDeleteModal(user.id);
                      }}
                    >
                      ลบผู้ใช้
                    </Button>
                    <Modal
                      title="ลบข้อมูล"
                      open={isDeleteModalOpen}
                      onOk={handleDeleteOk}
                      onCancel={handleDeleteCancel}
                    ></Modal>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEditRole, setSelectedEditRole] = useState(null);
  const [editName, setEditName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editPassword, setEditPassword] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    const service =
      selectedRole === "operator"
        ? selectedService
        : selectedRole === "admin"
        ? "admin"
        : selectedRole === "staff"
        ? "staff"
        : "";

    console.log(name);
    console.log(lastName);
    console.log(userName);
    console.log(password);
    console.log(selectedRole);
    console.log(service);
    try {
      const response = await axios.post(
        `${apiURL}createUser`,
        {
          firstName: name,
          lastName: lastName,
          username: userName,
          password: password,
          role: selectedRole,
          operators: service,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("POST request successful:", response.data);
      setIsModalOpen(false);
      setSelectedRole(null);
      setSelectedService(null);
      setName("");
      setLastName("");
      setUserName("");
      setPassword("");
      fetchData();
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (userId) => {
    setIsEditModalOpen(true);
    setUserIdToEdit(userId);
  };

  const handleEditOk = async () => {
    const editedService =
      selectedEditRole === "operator"
        ? selectedEditService
        : selectedEditRole === "admin"
        ? "admin"
        : selectedEditRole === "staff"
        ? "staff"
        : "";
    console.log(userIdToEdit);
    console.log(editName);
    console.log(editLastName);
    console.log(editUserName);
    console.log(editPassword);
    console.log(selectedEditRole);
    console.log(editedService);
    try {
      const editURL = `${apiURL}editUser/${userIdToEdit}`;
      const response = await axios.put(
        editURL,
        {
          firstName: editName,
          lastName: editLastName,
          username: editUserName,
          password: editPassword,
          role: selectedEditRole,
          operators: editedService,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User edited:", response.data);
      setIsEditModalOpen(false);
      setSelectedEditRole(null);
      setSelectedEditService(null);
      setEditName("");
      setEditLastName("");
      setEditUserName("");
      setEditPassword("");
      fetchData();
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
    if (selectedRole !== "operator") {
      setSelectedService();
    }
  };

  return (
    <div className="manage-scroll">
      <div className="import-outer">
        <div className="importContainer">
          <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            จัดการสิทธิผู้ใช้งาน
          </span>
          <Button type="primary" onClick={showModal}>
            เพิ่มรายการ
          </Button>
          <Modal
            title="เพิ่มผู้ใช้งาน"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="name">
              <p>ชื่อ</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div className="lastname">
              <p>นามสกุล</p>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></input>
            </div>
            <div className="username">
              <p>ซื่อผู้ใช้งาน</p>
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              ></input>
            </div>
            <div className="password">
              <p>รหัสผ่าน</p>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div className="role">
              <p>บทบาท</p>
              <Radio.Group
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <Radio value="admin">ผู้ดูแลระบบ</Radio>
                <Radio value="staff">เจ้าหน้าที่ กสทช.</Radio>
                <Radio value="operator">โอเปอร์เรเตอร์</Radio>
              </Radio.Group>
            </div>
            {selectedRole === "operator" && (
              <div className="service">
                <p>เครือข่าย</p>
                <Radio.Group
                  value={selectedService}
                  onChange={handleServiceChange}
                  disabled={selectedRole !== "operator"}
                >
                  {services.map((service) => (
                    <Radio key={service.id} value={service.company_name}>
                      {service.company_name}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            )}
          </Modal>
        </div>
        <div className="info-search">
          <Search
            placeholder="ค้นหา"
            style={{ width: 200 }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        {Table()}
      </div>
    </div>
  );
};

export default ManageUserPage;
