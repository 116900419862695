import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../Styles/Map.css";
import Province from './Province.js'

const Map = () => {
  return (
    <MapContainer
      center={[13.743393, 100.509374]}
      zoom={6}
      style={{ width: "38vw", height: "45vh", marginTop:"5px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Province/>
    </MapContainer>
  );
};

export default Map;
