import React, { useState, useContext, useEffect } from "react";
import "../Styles/SignInPage.css";
import { multiStepContext } from "../StepContext";
import axios from "axios";
import { message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const apiURL = process.env.REACT_APP_API_URL;

const SignInPage = () => {
  const {
    setStep,
    userData,
    setUserData,
    setFinalData,
    finalData,
    admin,
    setAdmin,
  } = useContext(multiStepContext);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [messageApi, contextHolder] = message.useMessage();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    clearInputData();
  }, []);

  const clearInputData = () => {
    setUserData({ username: '', password: '' });
  };

  const submitData = () => {
    setFinalData([...finalData, userData]);
    setUserData("");
  };

  const handleRemember = () => {
    setStep(2);
  };

  const handleSubmit = async () => {
    submitData();
    setUserData({ ...userData });
    try {
      const response = await axios.post(`${apiURL}login`, {
        username: userData.username,
        password: userData.password,
        remember: agreeTerms,
      });
      localStorage.setItem("token", response.data.token);
      setToken(localStorage.getItem("token"));
      return true;
    } catch (error) {
      onError();
      console.log(error);
    }
  };

  const onError = () => {
    messageApi.open({
      type: "error",
      content: "Incorrect Username or Password",
    });
  };

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        try {
          const response = await axios.get(`${apiURL}getCurrAdmin`, {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          setAdmin(response.data.data);
          setStep(3);
          // for clearing token
          // localStorage.clear();
          // setAdmin(null);
        } catch (error) {
          if (error.response.data.errorInfo.name === "TokenExpiredError") {
            alert("token expired");
            logout();
          }
          console.log(error);
        }
      };
      fetch();
      setStep(3);
    }
  }, [token]);

  const logout = () => {
    localStorage.clear();
    setAdmin(null);
    setStep(1);
  };

  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  return (
    <div className="signin-container">
      {contextHolder}
      <div className="signin-info">
        เข้าสู่ระบบจัดการข้อมูล
        <br />
        <span style={{ fontSize: "16" }}>Sign in</span>
      </div>
      <div className="signin-input">
        <div className="signin-id-con">
          ชื่อผู้ใช้งาน
          <input
            type="text"
            placeholder="โปรดกรอกข้อมูล"
            className="signin-status"
            value={userData["username"]}
            onChange={(e) =>
              setUserData({ ...userData, username: e.target.value })
            }
          ></input>
          <label htmlFor="password">รหัสผ่าน</label>
          <div>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="โปรดกรอกข้อมูล"
              className="signin-password"
              value={userData["password"]}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="toggle-bt"
            >
              {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </button>
          </div>
        </div>
      </div>
      <div className="box-pw">
        <label className="rememberMe">
          <input
            className="rememberMe"
            value={userData["remember"]}
            type="checkbox"
            checked={agreeTerms}
            onChange={handleCheckboxChange}
          />{" "}
          จดจําฉัน ?
        </label>
        <p onClick={handleRemember} className="forgotPassword">
          ลืมรหัสผ่าน
        </p>
      </div>
      <div className="signin-next-bt">
        <button onClick={handleSubmit} className="signin-condition-button">
          เข้าสู่ระบบ
        </button>
      </div>
    </div>
  );
};

export default SignInPage;
