import React from 'react'
import '../Styles/MobileFooter.css'
import aisSideLogo from '../Assets/ais-logo-side.png'
import dtacSideLogo from '../Assets/dtac-logo-side.png';
import trueSideLogo from '../Assets/true-logo-side.png';

const MobileFooter = () => {
  return (
    <div className="mobile-logo">
        <img src={aisSideLogo}></img>
        <img src={trueSideLogo}></img>
        <img src={dtacSideLogo}></img>
    </div>
  )
}

export default MobileFooter